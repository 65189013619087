<template>
  <v-layout column>
    <v-layout
      class="upload-preview flex-column grow"
      v-if="selectedUploadPreview || loadingPreview"
    >
      <v-progress-circular
        v-if="loadingPreview"
        indeterminate
        color="primary"
        size="40"
      ></v-progress-circular>
      <v-layout
        v-if="selectedUploadPreview && !loadingPreview"
        class="
          shrink
          align-center
          justify-start
          flex-row
          upload-preview__header
        "
      >
        <v-btn
          v-shortkey="['esc']"
          @shortkey="closeUploadPreview()"
          depressed
          plain
          fab
          class="align-self-start ml-2"
          @click="closeUploadPreview"
        >
          <v-icon>close</v-icon>
        </v-btn>
        <div class="grow text-center upload-preview__header__title">
          <span>{{ selectedUploadPreview.file.name }}</span>
        </div>
      </v-layout>
      <v-layout
        v-if="selectedUploadPreview && !loadingPreview"
        class="upload-preview__content"
      >
        <div v-if="selectedUploadPreview.file.type == 'application/pdf'">
          <pdf :src="selectedUploadPreview.src"> </pdf>
        </div>
        <v-img max-height="650px" contain v-else :src="selectedUploadPreview.src"></v-img>
      </v-layout>
    </v-layout>
    <v-layout v-else @dragover="setDragOver" @drop.prevent>
      <div
        @drop="dragFile"
        class="drag-layer"
        ref="dragLayer"
        :class="{
          'drag-layer--active': isDragOver,
          'drag-layer--disabled': !isDragOver,
        }"
      >
        <span>Solte aqui o seu arquivo</span>
      </div>
      <img ref="output" id="output" />
      <v-layout
        v-if="currentConversation && !isDragOver"
        class="flex-column events-wraper"
        ref="events-wraper"
      >
        <component
          v-for="(event, index) in currentConversation.messages"
          :key="index"
          v-bind:is="event.type"
          :event="event"
        >
        </component>
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from "vuex";
import goTo from 'vuetify/lib/services/goto'
export default {
  components: {
    sent: () => import("./MessageSent/OperatorAnswer.vue"),
    received: () => import("./MessageReceived/CustomerMessage.vue"),
    initiative: () => import("./Initiative/InitiativeDispatched.vue"),
    Pdf: () => import("vue-pdf"),
  },
  data() {
    return {
      isDragOver: false,
    };
  },
  computed: {
    ...mapGetters("rasaConversation", {
      currentConversation: "currentConversation",
    }),
    ...mapGetters("rasaUpload", {
      selectedUploadPreview: "preview",
      loadingPreview: "loading",
    }),
    ...mapGetters("rasaSessions", {
      sessions: "sessions",
    }),
  },
  methods: {
      ...mapActions("rasaEvents", {
      sendMessage: "send",
    }),
    ...mapMutations("rasaSessions", {
      setHasUnread: "setHasUnread",
    }),
    ...mapMutations("rasaUpload", {
      setPreview: "setPreview",
      removeSelectedPreview: "removePreview",
    }),
    ...mapActions("rasaUpload", {
      uploadFile: "upload",
      createFile: "create",
    }),
    async dragFile(event) {
      event.preventDefault();
      this.isDragOver = false;
      const file = event.dataTransfer.files[0];
      await this.uploadArchive(file);
      const payload = {
        messages: [{
          sender: this.currentConversation?.session.sender,
          content_type: this.selectedUploadPreview?.file?.type,
          content: {
            file_name: this.selectedUploadPreview?.file?.name,
            url: this.selectedUploadPreview?.src,
          },
          user_id: this.currentConversation?.session.user_id,
          session_id: this.currentConversation?.session.id,
      }]};
      await this.sendMessage(payload);
      this.removeSelectedPreview();
    },
    setDragOver(event) {
      event.preventDefault();
      this.isDragOver = true;
    },
    closeUploadPreview() {
      this.removeSelectedPreview();
    },
    async uploadArchive(file) {
      const payload = {
        info: [
          {
            content_type: file.type,
            size: file.size,
            name: file.name,
            type: file.type,
          },
        ],
        type: ["whatsapp"],
      };
      
      const { files }  = await this.createFile(payload);
      
      await this.uploadToS3(files, file);
      this.setPreview({ file, src: files[0].url });
    },
    async uploadToS3(files, fileData) {
      const file = files[0];
      const data = this.createS3UploadForm(file._storage, fileData);
      const params = {
        url: file._storage.url,
        data,
      };
      await this.uploadFile(params);
    },
    createS3UploadForm(storage, file) {
      let formData = new FormData();
      formData.append("Content-Type", storage.fields["Content-Type"]);
      formData.append("key", storage.fields.key);
      formData.append("AWSAccessKeyId", storage.fields.AWSAccessKeyId);
      formData.append("acl", storage.fields.acl);
      formData.append("policy", storage.fields.policy);
      formData.append("signature", storage.fields.signature);
      formData.append("file", file);
      return formData;
    },
    changeUnread(newValue) {
      const idx =  this.sessions.indexOf(
        this.sessions.find((obj) => obj.session.id == this.currentConversation.session.id)
      )
      this.setHasUnread({idx: idx, has_unread: newValue})
    },
    scrollBottom(){
      setTimeout(() => {
      this.$refs["events-wraper"].lastChild.scrollIntoView({behavior: "smooth"})
      }, 10);
    }
  },
  watch: {
    currentConversation:{
      deep: true,
      handler(newValue, oldValue) {
        this.scrollBottom()
      }
    },
  },
};
</script>

<style lang="scss">
.text-align-end {
  text-align: right;
}
.upload-preview {
  background-color: rgb(248, 248, 248);
  align-items: center;
  justify-content: center;
  &__header {
    background-color: rgb(225, 225, 225);
    width: 100%;
    text-align: center;
    &__title {
      margin-left: -56px;
      font-weight: 500;
    }
  }
  &__content {
    justify-content: center;
    align-items: center;
    max-width: 700px;
    canvas {
      height: 650px !important;
      max-height: 700px !important;
    }
  }
}
.events-wraper {
  overflow: scroll;
  max-height: inherit;
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0px rgba(179, 179, 179, 0.712);
    background-color: transparent;
  }
  &::-webkit-scrollbar {
    width: 0px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: hsl(0, 0%, 43%);
  }
}
.drag-layer {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  color: hsla(0, 0%, 89%, 0.877);
  &--disabled {
    height: 0px;
    width: 0px;
    visibility: hidden;
  }
  &--active {
    background: hsla(0, 0%, 27%, 0.75);
    z-index: 1000;
    width: inherit;
    height: inherit;
    flex-grow: 1;
  }
}

</style>