<template>
    <v-dialog class="dodo_editdsl" v-model="dialog" width="650"
      @keydown.esc="close" persistent>
      <template v-slot:activator="{ on }">
        <div v-on.stop="on" @click.stop v-on="on">
          <slot name="activator"></slot>
        </div>
      </template>
      <v-card 
        v-if="dialog"
        class="secondary-background"
      >
        <v-card-title class="headline py-3" primary-title>
          <span>Nova Sessão</span>
          <v-spacer></v-spacer>
          <v-btn text icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-card-title>
  
        <v-card-text class="mt-4">
          <v-form v-model="valid" ref="form">
            <v-autocomplete :disabled="loading" :items="filteredInitiatives"
              label="Selecione qual iniciativa você quer utilizar" item-text="name" return-object chips :loading="loading"
              v-model="item" >
              <template v-slot:selection="data">
                  {{ data.item.name }}
              </template>
              <template v-slot:item="data">
                <template>
                  <v-list-item-content>
                    <v-list-item-title v-html="data.item.name">
                    </v-list-item-title>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
            <v-text-field v-model="cellphone" label="Telefone" name="title">
            </v-text-field>
            <v-text-field v-model="crm_ref" label="CRM" name="crm_ref">
            </v-text-field>
            <div v-for="variable in item.vars" :key="variable">
                <v-text-field v-model="variables[variable]" :label="variable"> </v-text-field>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="dialog = false">Cancelar</v-btn>
          <v-btn color="primary" text @click="save">Iniciar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { mapActions, mapGetters, mapMutations } from "vuex";
  import RasaSessionService from '@/services/sessions'
  
  export default {
  
  
    data() {
      return {
        valid: false,
        dialog: false,
        item: {},
        loading: false,
        cellphone: "",
        crm_ref: "",
        variables: {},
      };
    },
    methods: {
      ...mapMutations({
        showSnackbar: "showSnackbar",
      }),
      ...mapActions('rasaEvents', {
        sendNewInitiative: 'sendNewInitiative'
      }),
      clearVariables() {
        this.item = {};
        this.cellphone = "";
        this.crm_ref = "";
        this.variables = {};
      },
      open() {
          this.clearVariables();
          this.item = {
              key: null,
          }
          this.dialog = true;
      },
      save() {
        var payload = { key: this.item.key, phonenumber: this.cellphone, crm_ref: parseInt(this.crm_ref), variables: this.variables };
        this.sendNewInitiative(payload);
        this.close();
      },
      close() {
        this.dialog = false;
      },
    },
    computed: {
        filteredInitiatives() {
                return this.iniativesList.filter(item => {
                return item.active == true && 
                        item.service_id == this.currentService.id &&
                        item.used_only_by_users == true
                })
        },
        ...mapGetters("rasaInitiative", {
            iniativesList: "list",
        }),
        ...mapGetters("rasaServices", {
        currentService: "currentService",
        }),
    },
  };
  </script>
  
  <style lang="scss">
  // dodo_editdsl
  code {
    background-color: inherit;
    font-size: inherit;
    color: inherit;
    display: inherit;
    font-size: inherit;
    font-weight: inherit;
    white-space: inherit;
    box-shadow: inherit;
    border-color: transparent;
    cursor: text;
  }
  
  code:after,
  kbd:after,
  code:before,
  kbd:before {
    content: "";
  }
  
  .prism-editor__line-numbers {
    height: auto;
  }
  
  .prism-editor-wrapper {
    height: 330px;
  }
  
  .remove-button :hover {
    cursor: pointer;
  }
  </style>
  