<template>
  <v-layout
    v-if="currentConversation.session.expired && currentService.type == 'WHATSAPP' && checkAttendent() && checkButtonsViability()"
    class="chat__answer-bar py-2 px-4 align-center secondary-background"
    v-shortkey="['shift', 'q']"
    @keydown.esc="showInitiativesMenu = !showInitiativesMenu"
    @shortkey="showInitiativesMenu = !showInitiativesMenu"
  >
    <v-menu
      top
      :offset-y="true"
      :value="showInitiativesMenu"
      style="position: absolute !important; z-index: 100 !important;"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn 
          v-bind="attrs"
          v-on="on"
          rounded 
          block 
          class="chat__answer-bar-btn tertiary-background"
        >
          <span>Disparar iniciativa</span>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="(item, index) in filteredUserInitiatives"
          :key="index"
        >
          <v-list-item-title 
            @click="sendInitiative(item.key)" 
            v-shortkey="[(index + 1)]"
            @shortkey="sendInitiative(item.key)"
          >
            {{ `${index+1} -- ${item.key} (${item.description || "sem descrição fornecida"})` }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-layout>
  <v-layout
    v-else-if="checkAttendent() && ['PENDING','WAITING_TREATMENT'].includes(currentConversation.session.status)"
    class="chat__answer-bar px-4 align-center justify-end secondary-background"
    v-shortkey="['shift', '#']"
    @shortkey="openQuickReplyDialog()"
  >
    <v-btn icon><v-icon>attach_file</v-icon></v-btn>
    <v-textarea
      class="chat__answer-bar__textarea mx-4 align-self-center pt-5"
      solo
      dense
      auto-grow
      :maxlength="currentService.type == 'SMS'? 160 : 1000" 
      rows="1"
      counter
      placeholder="mensagem"
      v-model="message"
      @keydown.enter="sendMessageToStore($event, message)"
      :class="`${this.$vuetify.theme.dark ? 'answer-bar-theme-dark' : 'answer-bar-theme-light'}`"
    ></v-textarea>
    <v-btn icon @click.stop="sendMessageToStore($event, message)"><v-icon>send</v-icon></v-btn>
    <QuickReplyDialog
      ref="quickReplyDialog"
      @send-message="sendQuickReply"
      @close-dialog="closeDialog"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  components: {
    QuickReplyDialog: () =>
      import(
        "@/pages/CustomerServiceRasa/pages/QuickReplyDialog/QuickReplyDialog.vue"
      ),
  },
  data() {
    return {
      message: null,
      selectedInitiatives: [],
      showInitiativesMenu: false
    };
  },
  async created(){
    this.selectedInitiatives = await this.getUsedOnlyByUser()
  },
  methods: {
    ...mapMutations({
      showSnackbar: "showSnackbar",
    }),
    ...mapActions("rasaEvents", {
      sendMessage: "send",
      sendQuickReplyToApi: "sendQuickReply",
      sendInitiative: "sendInitiative",
    }),
    ...mapActions("rasaQuickReplyList", {
      getQuickReplyList: "getAll",
    }),
    ...mapActions("rasaInitiative", {
      getUsedOnlyByUser: "getUsedOnlyByUser",
    }),
    async sendMessageToStore(event, message) {
      if (event.shiftKey) return;
      if (!message) {
        this.message = null;
        return
      }
      let data = {
        messages: [{
            sender: this.currentConversation.session.sender,
            content_type: "text",
            content: {"text": message},
            user_id: this.loggedUser.id,
            session_id: this.currentConversation?.session.id,
        }]
      }
      event.preventDefault();
      await this.sendMessage(data);
      this.message = null;
    },
    async openQuickReplyDialog() {
      this.$refs.quickReplyDialog.open()
    },
    checkButtonsViability() {
      if (
        ['PENDING','WAITING_TREATMENT'].includes(this.currentConversation.session.status)
      ) {return true}
      else {return false}
    },
    checkAttendent() {
      if (this.loggedUser?.id == this.currentConversation?.session.user_id)
        return true;
      return false;
    },
    closeDialog() {},
    sendQuickReply(quickReply) {
      const payload = { quick_reply_id: quickReply.macroID, variables: quickReply.macrosDictionary }
      this.sendQuickReplyToApi(payload)
    }
  },
  computed: {
    ...mapGetters("rasaConversation", {
      currentConversation: "currentConversation",
    }),
    ...mapGetters({
      loggedUser: "getLoggedUser",
    }),
    ...mapGetters("rasaServices", {
      currentService: "currentService",
    }),
    ...mapGetters("rasaInitiative", {
      initiativeList: "list",
    }),
    filteredUserInitiatives() {
      return this.initiativeList.filter((template) => {
        return template.used_only_by_users == 1
        && template.active == true
        && template.service_id == this.currentService.id
      }).sort((a, b) => a.id - b.id)
    }
  },
  watch: {
    currentConversation:{
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.session.id != oldValue.session.id) {
          this.showInitiativesMenu = false
        }
      }
    },
  },
};
</script>


<style lang="scss">
.answer-bar-theme-dark {
  .v-input__slot {
    background-color: #2a3942 !important;
  }
}
.answer-bar-theme-light {
  .v-input__slot {
    background-color: #fff !important;
  }
}
</style>
<style lang="scss" scoped>

.chat__answer-bar {
  box-shadow: none !important;
  min-height: 85px;
  max-height: 180px;
  flex-grow: 0;
  &__textarea {
    max-height: 100%;
    min-height: 90%;
    overflow: auto;
  }
}
.chat__answer-bar-btn {
  max-width: 100%;
  min-height: 90%;
}
</style>