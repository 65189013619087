<template>
  <v-dialog class="dodo_editdsl" v-model="dialog" width="650"
    @keydown.esc="close" persistent>
    <template v-slot:activator="{ on }">
      <div v-on.stop="on" @click.stop v-on="on">
        <slot name="activator"></slot>
      </div>
    </template>
    <v-card 
      v-if="dialog"
      class="secondary-background"
    >
      <v-card-title class="headline py-3" primary-title>
        <span>Férias</span>
        <v-spacer></v-spacer>
        <v-btn text icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-4">
        <v-form v-model="valid" ref="form">
          <v-autocomplete 
            :disabled="loading" 
            :items="usersList"
            label="Selecione um atendente que irá ou voltará de férias" 
            item-text="name" 
            item-value="id" 
            chips
            v-model="item.user_in_vacation" 
            :loading="loading"  
          >
            <template v-slot:selection="data">
              <v-chip :input-value="data.selected">
                <v-avatar left>
                  <v-img :src="verifyImg(data.item.profile_photo_url)"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <img :src="verifyImg(data.item.profile_photo_url)" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name">
                  </v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.email">
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
          <v-autocomplete 
            :disabled="loading" 
            :items="usersList"
            label="Atendente que irá ficar com os atentimentos em tratamento" 
            item-text="name" 
            item-value="id" 
            chips
            v-model="item.user_to_get_treatments" 
            :loading="loading" v-if="item.user_in_vacation && !getVacationStatus()"
            multiple
          >
            <template v-slot:selection="data">
              <v-chip :input-value="data.selected">
                <v-avatar left>
                  <v-img :src="verifyImg(data.item.profile_photo_url)"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <img :src="verifyImg(data.item.profile_photo_url)" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name">
                  </v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.email">
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="dialog = false">Cancelar</v-btn>

        <v-btn 
        v-if="item.user_in_vacation && item.user_to_get_treatments && !getVacationStatus()" 
          color="primary" 
          text
          @click="save">
            enviar para férias
        </v-btn>
        <v-btn v-else-if="item.user_in_vacation && getVacationStatus()" 
          color="primary" 
          text 
          @click="save">
            retirar de férias
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import RasaSessionService from '@/services/sessions'

export default {


  data() {
    return {
      valid: false,
      dialog: false,
      item: {},
      loading: false,
    };
  },
  methods: {
    ...mapMutations({
      showSnackbar: "showSnackbar",
    }),
    ...mapActions('rasaSessions', {
      editSession: 'editSession'
    }),
    clearVariables() {
      this.item = {};
    },
    open() {
        this.clearVariables();
        this.item = {
            user_in_vacation: null,
            user_to_get_treatments: null
        }
        this.dialog = true;
    },
    validateform() {
      return new Promise((resolve, reject) => {
        let test = this.$refs.form.validate()
        if(this.getVacationStatus() == false && this.item.user_to_get_treatments) {
          resolve()
        } else if (this.getVacationStatus() == true) {
          resolve()
        } else {
          console.log(this.getVacationStatus())
          console.log(this.item.user_to_get_treatments)
          reject()
        }
      })
    },
    save() {
      this.validateform().then(() => {
        let params = {
          user_in_vacation: this.item.user_in_vacation,
          user_to_get_treatments: this.item.user_to_get_treatments
        }
        RasaSessionService.post_vacation(params).catch(
          this.showSnackbar('Falha ao requisitar férias, api retornou erro, chame um TI!.', )
        )
        this.dialog = false;

      }).catch(() => {this.showSnackbar('Falha ao requisitar férias, verifique se não ha campos nulos no menu Férias.')})
    },
    close() {
      this.dialog = false;
    },

    verifyImg(imgUrl){
      return imgUrl ? imgUrl : "img/default.png"
    },
    getVacationStatus() {
      return this.usersList.filter(el => el.id == this.item.user_in_vacation)[0].in_vacation
    }
  },
  computed: {
    ...mapGetters("rasaUsers", {
      usersList: "userList",
    }),
  },
};
</script>

<style lang="scss">
// dodo_editdsl
code {
  background-color: inherit;
  font-size: inherit;
  color: inherit;
  display: inherit;
  font-size: inherit;
  font-weight: inherit;
  white-space: inherit;
  box-shadow: inherit;
  border-color: transparent;
  cursor: text;
}

code:after,
kbd:after,
code:before,
kbd:before {
  content: "";
}
.prism-editor__line-numbers {
  height: auto;
}

.prism-editor-wrapper {
  height: 330px;
}

.remove-button :hover {
  cursor: pointer;
}
</style>
