<template>
  <v-dialog class="dodo_editdsl" v-model="dialog" width="650"
    @keydown.esc="close" persistent>
    <template v-slot:activator="{ on }">
      <div v-on.stop="on" @click.stop v-on="on">
        <slot name="activator"></slot>
      </div>
    </template>
    <v-card 
      v-if="dialog"
      class="secondary-background"
    >
      <v-card-title class="headline py-3" primary-title>
        <span>Editar Conversa</span>
        <v-spacer></v-spacer>
        <v-btn text icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="mt-4">
        <v-form v-model="valid" ref="form">
          <v-text-field :rules="requiredRule" v-model="item.name" label="Nome completo do cliente" name="title">
          </v-text-field>
          <v-text-field v-mask="'###.###.###-##'" :rules="cpfRule" v-model="item.document" label="CPF"
            name="subtitle"></v-text-field>
          <v-text-field :rules="requiredRule" v-model="item.crm_ref" label="CRM" name="crm_ref">
          </v-text-field>
          <v-autocomplete :disabled="loading" :items="usersList"
            label="Atendente" item-text="name" item-value="id" chips
            v-model="item.user_id" :loading="loading">
            <template v-slot:selection="data">
              <v-chip :input-value="data.selected">
                <v-avatar left>
                  <v-img :src="verifyImg(data.item.profile_photo_url)"></v-img>
                </v-avatar>
                {{ data.item.name }}
              </v-chip>
            </template>
            <template v-slot:item="data">
              <template>
                <v-list-item-avatar>
                  <img :src="verifyImg(data.item.profile_photo_url)" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name">
                  </v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.email">
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-autocomplete>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn text @click="dialog = false">Cancelar</v-btn>

        <v-btn color="primary" text @click="save">Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      valid: false,
      dialog: false,
      item: {},
      loading: false,
      selectedAttendantId: null,
      requiredRule:  [v => !!v || 'Campo requirido'],
      cpfRule: [v => (v && v.length > 13) || 'Cpf invalido ou faltando caracteres']
    };
  },
  methods: {
    ...mapMutations({
      showSnackbar: "showSnackbar",
    }),
    ...mapActions('rasaSessions', {
      editSession: 'editSession'
    }),

    clearVariables() {
      this.item = [];
    },
    open(item) {
      if (item) {
        this.clearVariables();
        this.item = JSON.parse(JSON.stringify(item));
      }
      this.dialog = true;
    },
    validateform() {
      return new Promise((resolve, reject) => {
        if (this.getVacationStatus()) {
          this.showSnackbar("Usuário selecionado esta em férias!")
          reject()
        }
        let test = this.$refs.form.validate()
        if(test) {
          resolve()
        }
        if(!test) {
          this.showSnackbar("Falha ao editar conversa, verifique os campos obrigatórios!")
          reject()
        }
      })
    },
    save() {
      this.validateform().then(() => {
        let params = {
          customer: {
            name: this.item.name,
            document: this.item.document,
            crm_ref: this.item.crm_ref,
          },
          user_id: this.item.user_id  
        }

        this.dialog = false;
        this.editSession([this.currentConversation, params]);
        // this.$router.go()

      })
    },
    close() {
      this.dialog = false;
    },

    verifyImg(imgUrl){
      return imgUrl ? imgUrl : "img/default.png"
    },
    getVacationStatus() {
      return this.usersList.filter(el => el.id == this.item.user_id)[0].in_vacation
    }
  },
  computed: {
    ...mapGetters("rasaUsers", {
      usersList: "userList",
    }),

    ...mapGetters("rasaConversation", {
      currentConversation: "currentConversation",
    }),

  },
};
</script>

<style lang="scss">
// dodo_editdsl
code {
  background-color: inherit;
  font-size: inherit;
  color: inherit;
  display: inherit;
  font-size: inherit;
  font-weight: inherit;
  white-space: inherit;
  box-shadow: inherit;
  border-color: transparent;
  cursor: text;
}

code:after,
kbd:after,
code:before,
kbd:before {
  content: "";
}

.prism-editor__line-numbers {
  height: auto;
}

.prism-editor-wrapper {
  height: 330px;
}

.remove-button :hover {
  cursor: pointer;
}
</style>
