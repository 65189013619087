<template>
  <v-layout>
    <v-navigation-drawer permanent class="chat__list d-flex primary-background" width="300px" fill-height>
      <BackButton class="flex-grow-0"/>
      <v-layout shrink v-if="['WAITING_TREATMENT', 'CLOSING', 'SEARCH'].indexOf(statusToShow) != -1">
        <v-layout shrink pa-3 align-center :mb-0="pendingsCount.total == 0">
          <v-layout py-2 column v-if="statusToShow == 'WAITING_TREATMENT'">
            <span class="font-weight-bold title--text mb-1"
              >Aguardando tratamento</span
            >
            <span class="subheading"
              >{{ filteredConversations.length }} conversas</span
            >
          </v-layout>
          <v-layout py-2 column v-else-if="statusToShow == 'CLOSING'">
            <span class="font-weight-bold title--text mb-1"
              >Fechados recentemente</span
            >
            <span class="subheading"
              >{{ filteredConversations.length }} conversas</span
            >
          </v-layout>
          <v-layout py-2 column v-else>
            <span class="font-weight-bold title--text mb-1"
              >Pesquisa</span
            >
            <span class="subheading"
              >{{ filteredConversations.length }} conversas</span
            >
          </v-layout>
        </v-layout>
        <v-layout column align-center justify-center>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                v-on="on"
                class="mr-0"
                color="grey darken-1"
                @click="changeStatusToShow('PENDING')"
                outlined
                small
                fab
              >
                <v-icon >close</v-icon>
                
              </v-btn>
            </template>
            <span>Fechar histórico</span>
          </v-tooltip>
        </v-layout>
      </v-layout>

      <v-layout
        class="pa-3"
        align-center
        shrink
        :mb-0="pendingsCount.total == 0"
        v-if="statusToShow == 'PENDING'"
      >
        <v-layout column>
          <v-tooltip bottom :position-x="0">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="font-weight-bold headline">{{
                pendingsCount.total
              }}</span>
              <span class="subheading">atendimentos pendentes</span>
            </template>
            <span v-html="parsePendingsCount()"></span>
          </v-tooltip>
        </v-layout>

        <v-tooltip bottom v-if="pendingsCount.total > 0">
          <template v-slot:activator="{ on }">
            <v-btn
              @click="checkForNextSession()"
              v-on="on"
              class="mr-0"
              outlined
              medium
              fab
              color="blue"
            >
              <v-icon>move_to_inbox</v-icon>
            </v-btn>
          </template>
          <span>Próxima conversa</span>
        </v-tooltip>
        <v-menu
          bottom
          :offset-x="true"
          v-if="pendingsCount.total > 0"
          style="position: absolute; z-index: 100;"
        >
          <template v-slot:activator="{ on, attrs }">
              <v-icon 
              v-bind="attrs"
              v-on="on"
              small>more_vert</v-icon>
          </template>
          <v-list class="tertiary-background">
            <v-list-item>
              <v-list-title>
                <b>Puxar: </b>
              </v-list-title>
              <v-list-item-title>
                <v-text-field
                  solo
                  :class="`${this.$vuetify.theme.dark ? 'input-theme-dark' : 'input-theme-light'}`"
                  hide-details
                  autofocus
                  v-model="nToPush"
                  @input="setQtdSessionsToPush(nToPush)"
                ></v-text-field>
              </v-list-item-title>
            </v-list-item>
              
            <v-list-item
              v-for="(item, index) in sessionsSources"
              :key="index"
              class="custon-v-list"
            >
              <v-list-item-icon class="custon-v-list-icon">
                <v-icon x-small color="purple" v-if="prioritySessionsSource == item.value">radio_button_checked</v-icon>
                <v-icon x-small v-else>radio_button_unchecked</v-icon>
              </v-list-item-icon>
              <v-list-item-title 
                class="custon-v-list-title"
                style="cursor: pointer;"
                @click="setPrioritySessionsSource(item.value)"
              >
                {{ item.item }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-layout>
      <v-layout shrink pb-2 v-if="statusToShow == 'PENDING'">
        <a
          @click="changeStatusToShow('WAITING_TREATMENT')"
          class="mx-auto text-caption"
        >
          Aguardando tratamento
        </a>
        <a
          @click="changeStatusToShow('CLOSING')"
          class="mx-auto text-caption red--text"
        >
          Recém fechados
        </a>
      </v-layout>
      <v-divider></v-divider>
      <v-layout class="conversation__list" grow column>
        <v-list
          v-if="filteredConversations.length != 0 && loadingConversions == false"
          class="my-0 py-0 conversationList flex-grow-1 ignore-vue-based"
        >          
          <v-list-item-group class="vlist-custom_focus">
            <template v-for="(item, index) in filteredConversations">
              <v-list-item
                justify-start-between
                class="item-tile py-1"
                :class="{
                  'v-item--active v-list-item--active': item.session.id == currentConversation.session.id
                }"
                :key="item.session.id"
                @click="changeCurrentConversation(item)"
              >
                <v-layout v-if="item.session.customer" column grow>
                  <v-layout align-center>
                    <div class="customer-name">
                      {{ item.session.customer.name | firstName }}
                    </div>
                  </v-layout>
                  <v-layout v-if="item.last_message.content_type == 'text'">
                    <div class="customer-document font-weight-light caption">
                      {{ item.last_message.content.text | limitTo(25) }}
                    </div>
                  </v-layout>
                  <v-layout v-else-if="item.last_message.content_type == 'initiative'">
                    <div class="customer-document font-weight-light caption">
                      {{ item.last_message.content.html | limitTo(25) }}
                    </div>
                  </v-layout>
                  <v-layout v-else>
                    <v-icon color='grey' small>description</v-icon>
                  </v-layout>
                </v-layout>

                <v-layout v-else column grow>
                  <span>Sem referência do cliente</span>
                </v-layout>
                <v-layout class="ml-2" column align-end shrink>
                  <v-layout class="pb-1" align-center>
                    <v-icon class="pr-4" color='#32CD32' small v-if="item.session.has_unread && user && user.profile_photo_url">circle</v-icon>
                    <v-icon color='#32CD32' small v-else-if="item.session.has_unread">circle</v-icon>
                    <v-layout class="ml-2" column grow>
                      <v-tooltip v-if="user && user.profile_photo_url" bottom>
                        <template v-slot:activator="{ on }">
                          <v-avatar :size="21" color="grey lighten-4" v-on="on">
                            <img :src="user.profile_photo_url" alt="avatar" />
                          </v-avatar>
                        </template>
                        <span>{{ user.name }}</span>
                      </v-tooltip>
                    </v-layout>
                  </v-layout>
                  <v-layout
                    v-if="item.last_message"
                    column
                    grow
                    align-end
                    justify-end
                  >
                    <span class="date font-weight-light grey-text caption">{{
                      (item.last_message.created_at)
                        | moment("from", "now")
                    }}</span>
                  </v-layout>
                </v-layout>
              </v-list-item>
              <v-divider :key="`${index}_${item.session.id}`"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
        <v-layout
          v-else-if="loadingConversions"
          class="d-flex flex-column flex-grow-1 py-0 conversationList"
          grow
          column
          align-center
          justify-center
        >
          <v-skeleton-loader
            class="d-flex flex-column justify-flex-start"
            width="100%"
            :types="skeletonTypes"
            type="conversation"
          >
          </v-skeleton-loader>
        </v-layout>
        <v-layout v-else align-center justify-center>
          <span>Nenhum atendimento encontrado</span>
        </v-layout>
      </v-layout>

      <v-layout
        v-if="searchError"
        px-2
        shrink
      >
        <v-alert
          type="error"
          class="mb-0 text-center"
        >
          <p>
            Não foi possível pesquisar os atendimentos.
          </p>

          <v-btn
            @click="doSearch"
          >
            Tentar novamente
          </v-btn>
        </v-alert>
      </v-layout>

      <v-layout px-2 justify-center align-center class="fast-search" shrink>
        <v-text-field 
          class="tertiary-background"
          placeholder="Pesquisar" 
          hide-details
          v-model="searchText" 
          @keydown.enter="doSearch" 
          outlined 
          prepend-inner-icon="search">
        </v-text-field>
      </v-layout>
    </v-navigation-drawer>
  </v-layout>
</template>


<script>
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    BackButton: () => import("./backButton"),
  },
  async created() {
    await this.getPendingsCount();
  },
  data() {
    return {
      /** Indica se houve um erro na pesquisa das sessões. */
      searchError: false,

      loadingConversions: false,
      skeletonTypes: {
        conversation: "list-item-avatar-two-line@20",
      },
      nToPush: 10,
      sessionsSources: [
        { item: 'Sem preferência', value: null },
        { item: 'Atendimento', value: 'AT' },
        { item: 'Reclame Aqui', value: 'RA' },
        { item: 'Estímulos', value: 'ST' },
      ]
    };
  },
  computed: {
    ...mapGetters("rasaSearchSessions", {
      searchSessions: "searchSessions",
      searchTextFromStore: "searchText",
    }),
    ...mapGetters("rasaClosedSessions", {
      closedSessions: "closedSessions",
    }),
    searchText: {
      get() {
        return this.searchTextFromStore
      },
      set(value) {
        this.setSearchText(value)
      },
    },
    filteredConversations() {
      if (this.statusToShow == 'SEARCH'){
        return this.searchSessions

      } else if (this.statusToShow == 'CLOSING') {
        return this.closedSessions

      } else {
        return this.conversations.filter(item => {
          return item.session.status == this.statusToShow && 
                 item.session.service_id == this.currentService.id
        })
      }
    },
    ...mapGetters("rasaConversation", {
      currentConversationGetter: "currentConversation",
    }),
    ...mapGetters("rasaServices", {
      currentService: "currentService",
    }),

    ...mapGetters("rasaSessions", {
      conversations: "sessions",
      pendingsCount: "sessionsPendingCount",
      statusToShow: "statusToShow",
      prioritySessionsSource: "prioritySessionsSource",
      qtdSessionsToPush: "qtdSessionsToPush",
    }),

    ...mapGetters({
      user: "getLoggedUser",
    }),

    currentConversation: {
      get() {
        return this.currentConversationGetter;
      },
      set(newValue) {
        this.changeCurrentConversation(newValue);
      },
    },
  },

  methods: {
    ...mapMutations("rasaConversation", {
      setCurrentConversation: "setCurrentConversation",
    }),
    
    ...mapMutations({
      showSnackbar: "showSnackbar",
    }),

    ...mapMutations("rasaSessions", {
      setStatusToShow: "setStatusToShow",
      setPrioritySessionsSource: "setPrioritySessionsSource",
      setQtdSessionsToPush: "setQtdSessionsToPush",
    }),

    ...mapMutations("rasaClosedSessions", {
      resetClosedSessions: "resetClosedSessions",
    }),

    ...mapMutations("rasaSearchSessions", {
      resetSearchSessions: "resetSearchSessions",
      setSearchText: "setSearchText",
    }),

    ...mapActions("rasaClosedSessions", {
      getClosedSessions: "getClosedSessions",
    }),

    ...mapActions("rasaConversation", {
      changeCurrentConversation: "changeCurrentConversation",
      clearCurrentConversation: "clearCurrentConversation",
    }),

    ...mapActions("rasaSearchSessions", {
      getSearchSessions: "getSearchSessions",
    }),

    ...mapActions("rasaSessions", {
      getNextSession: "getNextSession",
      showWaitingTreatment: "showWaitingTreatment",
      backToNormal: "backToNormal",
      getPendingsCount: "getPendingsCount"
    }),

    parsePendingsCount() {
      let text = ''
      for (var idx in this.sessionsSources) {
        if (this.sessionsSources[idx].value == null) {
          continue
        }
        text += `${this.sessionsSources[idx].item}: ${this.pendingsCount[this.sessionsSources[idx].value] || 0} </br>`

      } 
      return text
    },

    checkForNextSession() {
      if (this.user.in_vacation) {
        this.showSnackbar("Você esta de férias, aproveite!")
      } else {
        this.getNextSession()
      }
    },

    changeStatusToShow(status) {
      this.setSearchText(null)

      if (status == 'PENDING') {
        this.backToNormal()
        this.resetSearchSessions()
        this.resetClosedSessions()

      } else if (status == 'CLOSING') {
        this.resetSearchSessions()
        this.getClosedSessions()

      } else if (status == 'WAITING_TREATMENT') {
        this.showWaitingTreatment() 
        this.resetSearchSessions()
        this.resetClosedSessions()
      }
      this.setStatusToShow(status)
    },

    async doSearch() {
      if (this.searchTextFromStore != null && this.searchTextFromStore != '') {
        this.loadingConversions = true
        this.searchError = false

        try {
          await this.getSearchSessions()
        } catch (error) {
          console.error('Não foi possível pesquisar as sessões.', error)

          this.loadingConversions = false
          this.searchError = true

          return
        }

        this.changeStatusToShow('SEARCH')
        this.loadingConversions = false
        this.showSnackbar(`${this.filteredConversations.length} conversas encontradas`)
      } else if (this.searchTextFromStore == null || this.searchTextFromStore == '') {
        this.resetSearchSessions()
      }
    }
  },
};
</script>
<style lang="scss">
.status-conversation {
  margin-right: 10px;
  font-size: 11px;
  font-weight: 500;
  border-radius: 3px;
  margin-left: 5px;
  height: 17px;
  width: 17px;
  text-align: center;

  color: white;
  background-color: gray;

  &.Aberto {
    color: white;
    background-color: #2196f3;
  }

  &.DISCARDED {
    color: white;
    background-color: gray;
  }

  &.Reaberto {
    color: white;
    background-color: #ff9800;
  }

  &.RESOLVED {
    color: white;
    background-color: #4caf50;
  }
}

.input-theme-dark {
  .v-input__slot {
    background-color: #2a3942 !important;
  }
}
.input-theme-light {
  .v-input__slot {
    background-color: #f0f2f5 !important;
  }
}
</style>

<style lang="scss">
.custon-v-list {
  height: 2px;
  min-height: 40px;
}

.custon-v-list-icon {
  margin-right: 2px !important;
}

.custon-v-list-title {
  margin-top: 5px;
}

.conversationList {
  overflow-y: auto;
  height: calc(100vh - 64px - 80px - 28px - 85px - 2px);
}

.chat__list {
  max-height: 100vh;
  height: 100vh;
  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
    overflow-y: hidden !important;
  }
}
.customer-document {
  font-size: 14px;
  color: #666;
  font-weight: 300;
}

.customer-name {
  overflow-y: hidden;
  font-size: 17px;
  line-height: 18px;
  font-weight: 450;
}
.fast-search {
  // position: relative;
  padding-right: 0px;
  max-width: 300px;
  min-height: 85px;
  max-height: 85px;
  .v-btn {
    &:not(.type-active) {
      &:not(:hover) {
        .v-icon {
          color: gray;
        }
      }
    }
  }
.v-item--active .v-list-item--active {
  color:#1e5c5ce8 !important;
}
}
</style>