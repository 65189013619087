<template>
  <v-layout fill-height class="app-container">
    <v-layout
      pa-0
      class="shrink"
      style="max-height: 100vh min-height: 100vh!important"
    >
      <ChatList />
    </v-layout>
    <v-divider vertical></v-divider>
    <v-layout
      class="grow"
      :class="`${this.$vuetify.theme.dark ? 'conversation-dark' : 'conversation-light'}`"
    >
      <v-layout class="flex-column events-container">
        <ChatToolbar class="shrink" />
        <ChatEvents
          class="events-container"
          ref="chatEvents"
          :key="currentConversation.session.id"
        />
        <!-- <v-layout shrink v-if="currentConversation.session.customer.suspect"> -->
        <v-card
          class="card-alert ignore-vue-based"
          max-height="100%"
          fill-width
          v-if="currentConversation.session.customer.suspect"
        >
          <v-card-title class="justify-center white--text">
            <h1>Atenção!</h1>
          </v-card-title>
          <v-card-text
            mx-10
            style="font-size: 18px; word-break: break-word"
            class="text-center white--text"
          >
            <p style="font-size: 18px; word-break: break-word">
              Este número de telefone possui mais de um contato registrado.
              Verifique a identidade da pessoa e em seguida edite a conversa.
              <br />
              Caso seja a pessoa correta na conversa, entre no menu de editar a
              conversa e clique em salvar sem alterar nenhuma informação.
            </p>
          </v-card-text>
        </v-card>

        <ChatAnswerBar class="" v-if="currentConversation.session.id" />
      </v-layout>
    </v-layout>
  </v-layout>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import ChatList from "./components/ChatList.vue";
import ChatToolbar from "./components/ChatToolBar.vue";
import ChatEvents from "./components/ChatEvents.vue";
import ChatAnswerBar from "./components/ChatAnswerBar.vue";

export default {
  components: {
    ChatList,
    ChatToolbar,
    ChatEvents,
    ChatAnswerBar,
  },

  async created() {
    await this.checkServices();
    await this.preLoad();
    await this.getWaitingTreatment();
  },
  beforeDestroy() {
    this.clearConversationHistory()
  },
  computed: {
    ...mapGetters("rasaConversation", {
      currentConversation: "currentConversation",
    }),
    ...mapGetters("rasaServices", {
      currentService: "currentService",
    }),
  },
  methods: {
    ...mapActions("rasaConversation", {
      preLoad: "preLoad",
      clearConversationHistory: "clearConversationHistory"
    }),
    ...mapActions("rasaSessions", {
      getWaitingTreatment: "getWaitingTreatment",
    }),
    ...mapActions("rasaServices", {
      checkServices: "checkServices",
    }),
  },
  watch: {
    currentConversation(newValue, oldValue) {
      setTimeout(() => {
        this.$refs['chatEvents'].scrollBottom();
      }, 200)
    },
  },
};
</script>

<style lang="scss" scoped>
.app-container {
  max-height: 100vh;
  .events-container {
    max-height: inherit;
    overflow: auto;
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0px rgba(179, 179, 179, 0.712);
      background-color: transparent;
    }
    &::-webkit-scrollbar {
      width: 0px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      background-color: hsl(0, 0%, 43%);
    }
  }
}
.conversation-light {
  background-repeat: repeat;
  background-image: url("/img/wppBg.png");
}

.conversation-dark {
  background-repeat: repeat;
  background-image: url("/img/wppBgDark.png");
  .document {
    color: gray;
  }
  .expires-in {
    color: gray;
    font-size: 12px;
    line-height: 16px;
  }
}
.card-alert {
  background-color: red;
  font-size: 34px;
  // height: 100px;
}
</style>